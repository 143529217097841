<style lang='less'>
  .page-403-vue {
    .background-image {
      background-image: url(../../images/error-pages/403.png);
    }
  }
</style>
<template>
  <div class="page-403-vue error-page">
    <div class="background-image"></div>
    <p>您无权访问此页面~~</p>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    }
  },
  computed: {

  }
};
</script>
