export default [
  { id: '1', name: '万事屋', parent: '-1' },
  { id: '1.1', name: '坂田银时', parent: '1' },
  { id: '1.2', name: '志村新八', parent: '1' },
  { id: '1.3', name: '神乐', parent: '1' },
  { id: '1.4', name: '定春', parent: '1' },
  { id: '2', name: '真选组', parent: '2' },
  { id: '2.1', name: '近藤勋', parent: '2' },
  { id: '2.2', name: '土方十四郎', parent: '2' },
  { id: '2.3', name: '冲田总悟', parent: '2' },
  { id: '2.4', name: '山崎退', parent: '2' },
  { id: '2.5', name: '伊东鸭太郎', parent: '2' },
  { id: '2.6', name: '松平片栗虎', parent: '2' },
  { id: '2.7', name: '神山', parent: '2' },
  { id: '2.8', name: '未登场的队士们', parent: '2' },
  { id: '3', name: '攘夷志士', parent: '-1' },
  { id: '3.1', name: '桂小太郎', parent: '3' },
  { id: '3.2', name: '伊丽莎白（艾利撒比斯）', parent: '3' },
  { id: '3.3', name: '宫部', parent: '3' },
  { id: '4', name: '私设舰队『快援队』', parent: '-1' },
  { id: '4.1', name: '坂本辰马', parent: '4' },
  { id: '4.2', name: '陆奥', parent: '4' },
  { id: '5', name: '私人武装组织『鬼兵队』', parent: '-1' },
  { id: '5.1', name: '高杉晋助', parent: '5' },
  { id: '5.2', name: '河上万齐', parent: '5' },
  { id: '5.3', name: '冈田似蔵', parent: '5' },
  { id: '5.4', name: '来岛又子', parent: '5' },
  { id: '5.5', name: '武市变平太', parent: '5' },
  { id: '6', name: '宇宙海盗『春雨』', parent: '-1' },
  { id: '6.1', name: '神威', parent: '6' },
  { id: '6.2', name: '阿伏兔', parent: '6' },
  { id: '6.3', name: '云业', parent: '6' },
  { id: '6.4', name: '陀络', parent: '6' },
  { id: '7', name: '歌舞伎町四天王', parent: '-1' },
  { id: '7.1', name: '登势大婶', parent: '7' },
  { id: '7.2', name: '凯萨琳（卡莎莲）', parent: '7' },
  { id: '7.3', name: '玉子', parent: '7' },
  { id: '7.4', name: '孔雀姬华佗', parent: '7' },
  { id: '7.5', name: '西乡特盛', parent: '7' },
  { id: '7.6', name: '侠客泥水次郎长', parent: '7' },
  { id: '7.7', name: '黑驹胜男', parent: '7' },
  { id: '8', name: '其他经常登场的人', parent: '-1' },
  { id: '8.1', name: '吉田松阳', parent: '8' },
  { id: '8.2', name: '志村妙', parent: '8' },
  { id: '8.3', name: '长谷川泰三', parent: '8' },
  { id: '8.4', name: '柳生九兵卫', parent: '8' },
  { id: '8.5', name: '几松', parent: '8' },
  { id: '8.6', name: '寺门通', parent: '8' },
  { id: '8.7', name: '哈达王子（哈打王子）', parent: '8' },
  { id: '8.8', name: '服部全藏', parent: '8' },
  { id: '8.9', name: '猿飞菖蒲', parent: '8' },
  { id: '8.10', name: '屁怒絽', parent: '8' },
  { id: '8.11', name: '星海坊主', parent: '8' },
  { id: '8.12', name: '结野主播', parent: '8' },
  { id: '8.13', name: '花野咲', parent: '8' },
  { id: '9', name: '其他曾经登场的重要人物', parent: '-1' },
  { id: '9.1', name: '才藏', parent: '9' },
  { id: '9.2', name: '阿国', parent: '9' },
  { id: '9.3', name: '冲田三叶', parent: '9' },
  { id: '9.4', name: '德川茂茂', parent: '9' },
  { id: '9.5', name: '月咏', parent: '9' },
  { id: '9.6', name: '日轮', parent: '9' },
  { id: '9.7', name: '晴太', parent: '9' },
  { id: '9.8', name: '白血球', parent: '9' }
];
