<style lang='less'>
  .page-500-vue {
    .background-image {
      background-image: url(../../images/error-pages/500.png);
    }
  }
</style>
<template>
  <div class="page-500-vue error-page">
    <div class="background-image"></div>
    <p>您访问的页面出现错误~~</p>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    }
  },
  computed: {

  }
};
</script>
